<template>
  <Modal
    :dialog="dialog"
    :hour-slot="openingSlot"
    :title-create="$t('openingHours.modal.titleCreate')"
    :title-update="$t('openingHours.modal.titleUpdate')"
    :title-delete="$t('openingHours.modal.titleDelete')"
    :description-delete="$t('openingHours.modal.descriptionDelete')"
    @close="$emit('close')"
    @delete="onDelete"
    @submit="onSubmit"
  >
    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 sm:tw-gap-6">
      <v-text-field
        class="sm:tw-col-span-2"
        :label="$t('openingHours.modal.slotName')"
        v-model="slotName"
        outlined
        hide-details
      ></v-text-field>
      <v-menu
        ref="menuStartDate"
        v-model="menuStartDate"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedStartDate"
            :label="$t('openingHours.modal.startDate')"
            outlined
            clearable
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          :max="endDate"
          @input="$refs.menuStartDate.save(startDate)"
          :locale="$i18n.locale"
          :first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
      <v-menu
        ref="menuEndDate"
        v-model="menuEndDate"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedEndDate"
            :label="$t('openingHours.modal.endDate')"
            outlined
            clearable
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          :min="startDate"
          @input="$refs.menuEndDate.save(endDate)"
          :locale="$i18n.locale"
          :first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
    </div>

    <div class="modal-opening-hours__grid">
      <div
        class="modal-opening-hours__grid__wrapper tw-mt-4 sm:tw-mt-6"
        v-for="(day, idx) in daysArray"
        :key="`day-${day.key}-${idx}`"
      >
        <div class="modal-opening-hours__grid__wrapper__block tw-gap-4 sm:tw-gap-6">
          <div class="modal-opening-hours__grid__wrapper__block__day">
            <template v-if="$vuetify.breakpoint.xsOnly"> {{ $t(`common.days.${day.key}`).slice(0, 3) }}. </template>
            <template v-else>
              {{ $t(`common.days.${day.key}`) }}
            </template>
          </div>
          <v-switch
            class="modal-opening-hours__grid__wrapper__block__status tw-mt-0 tw-pt-0"
            v-model="day.open"
            @change="toggleSlots(day)"
            hide-details
            :label="day.open ? $t('common.label.open') : $t('common.label.closed')"
          >
          </v-switch>
          <v-checkbox
            class="modal-opening-hours__grid__wrapper__block__h24 tw-mt-0 tw-pt-0"
            :label="$t('common.label.h24')"
            v-model="day.h24"
            v-if="day.open"
            hide-details
            @change="toggleSlots(day)"
          >
          </v-checkbox>
          <div class="modal-opening-hours__grid__wrapper__block__duplicate">
            <v-menu
              v-model="duplicateMenu[idx]"
              offset-y
              left
              :close-on-content-click="false"
              max-width="300"
              transition="slide-y-reverse-transition"
              @input="stopDuplicating"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn
                  @click="duplicate(day)"
                  :icon="$vuetify.breakpoint.xsOnly"
                  color="primary"
                  text
                  rounded
                  large
                  v-bind="attrs"
                  v-on="{ ...menu }"
                >
                  <v-icon :left="!$vuetify.breakpoint.xsOnly"> mdi-content-copy </v-icon>
                  <span v-if="!$vuetify.breakpoint.xsOnly">
                    {{ $t('openingHours.modal.cta.duplicate') }}
                  </span>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link v-for="(day, idxDay) in daysArray" :key="`checkbox-day-${idxDay}`">
                  <v-checkbox
                    class="tw-mt-0 tw-pt-0 modal-opening-hours__grid__wrapper__block__duplicate__checkbox"
                    v-model="duplicateArray"
                    hide-details=""
                    :value="day.key"
                    :label="$t(`common.days.${day.key}`)"
                    :disabled="duplicatingDay && day.key === duplicatingDay.key"
                  ></v-checkbox>
                </v-list-item>
                <v-btn color="primary" text @click="endDuplicating(idx)">
                  {{ $t('openingHours.modal.cta.endDuplicate') }}
                </v-btn>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="modal-opening-hours__grid__wrapper__slots" v-if="day.open && !day.h24">
          <div
            class="modal-opening-hours__grid__wrapper__slots__line tw-flex tw-items-center tw-gap-4 sm:tw-gap-6"
            v-for="(slot, idxSlot) in day.slots"
            :key="`slot-${day.key}-${idxSlot}`"
          >
            <v-menu
              :ref="`menuOpeningTime-${idx}-${idxSlot}`"
              v-model="slot.menuOpening"
              :close-on-content-click="false"
              :return-value.sync="slot.opening"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="360px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slot.opening"
                  :error-messages="openingErrors(idx, idxSlot)"
                  :label="$t('common.label.opening')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="slot.menuOpening"
                v-model="slot.opening"
                :allowed-minutes="allowedStep"
                full-width
                format="24hr"
                @click:minute="$refs[`menuOpeningTime-${idx}-${idxSlot}`][0].save(slot.opening)"
              ></v-time-picker>
            </v-menu>
            <v-menu
              :ref="`menuClosingTime-${idx}-${idxSlot}`"
              v-model="slot.menuClosing"
              :close-on-content-click="false"
              :return-value.sync="slot.closing"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slot.closing"
                  :error-messages="closingErrors(idx, idxSlot)"
                  :label="$t('common.label.closing')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="slot.menuClosing"
                v-model="slot.closing"
                :allowed-minutes="allowedStep"
                full-width
                format="24hr"
                @click:minute="$refs[`menuClosingTime-${idx}-${idxSlot}`][0].save(slot.closing)"
              ></v-time-picker>
            </v-menu>
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="modal-opening-hours__grid__wrapper__slots__line__cta"
                  v-if="idxSlot === 0"
                  :disabled="
                    !day.slots[day.slots.length - 1].opening ||
                    !day.slots[day.slots.length - 1].closing ||
                    day.slots.length === 2
                  "
                  @click="addSlot(day)"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-alarm-plus </v-icon>
                </v-btn>
              </template>
              {{ $t('common.cta.addSlot') }}
            </v-tooltip>
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="modal-opening-hours__grid__wrapper__slots__line__cta"
                  v-if="idxSlot > 0"
                  @click="deleteSlot(day, idxSlot)"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              {{ $t('common.cta.removeSlot') }}
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { formatedDate } from '@/utils/date.util'
import Modal from '@/components/Modal.vue'

export default {
  name: 'ModalOpeningHours',
  components: {
    Modal,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    openingSlot: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return this.initialState()
  },
  mixins: [validationMixin],
  watch: {
    dialog(val) {
      if (val) {
        this.$v.$reset()
        Object.assign(this.$data, this.initialState())
        this.generateDays()
      }
    },
  },
  computed: {
    computedStartDate: {
      get() {
        return this.startDate ? formatedDate(this.startDate, 'L', this.$i18n.locale) : ''
      },
      set(date) {
        this.startDate = date ? date : ''
      },
    },
    computedEndDate: {
      get() {
        return this.endDate ? formatedDate(this.endDate, 'L', this.$i18n.locale) : ''
      },
      set(date) {
        this.endDate = date ? date : ''
      },
    },
  },
  methods: {
    initialState() {
      return {
        menuStartDate: false,
        menuEndDate: false,
        slotName: '',
        startDate: null,
        endDate: null,
        daysArray: [
          {
            key: 'monday',
            open: false,
            h24: false,
            slots: [],
          },
          {
            key: 'tuesday',
            open: false,
            h24: false,
            slots: [],
          },
          {
            key: 'wednesday',
            open: false,
            h24: false,
            slots: [],
          },
          {
            key: 'thursday',
            open: false,
            h24: false,
            slots: [],
          },
          {
            key: 'friday',
            open: false,
            h24: false,
            slots: [],
          },
          {
            key: 'saturday',
            open: false,
            h24: false,
            slots: [],
          },
          {
            key: 'sunday',
            open: false,
            h24: false,
            slots: [],
          },
        ],
        duplicatingDay: null,
        duplicateArray: [],
        duplicateMenu: [],
      }
    },
    toggleSlots(item) {
      this.daysArray = this.daysArray.map(day => {
        if (day.key === item.key) {
          return {
            ...day,
            slots:
              day.open && !day.h24
                ? [
                    {
                      opening: '',
                      closing: '',
                      menuOpening: false,
                      menuClosing: false,
                    },
                  ]
                : [],
          }
        } else {
          return day
        }
      })
    },
    allowedStep(m) {
      return m % 5 === 0
    },
    addSlot(data) {
      data.slots.push({
        opening: '',
        closing: '',
        menuOpening: false,
        menuClosing: false,
      })
    },
    deleteSlot(data, index) {
      data.slots.splice(index, 1)
    },
    openingErrors(idx, idxSlot) {
      const errors = []
      if (!this.$v.daysArray.$each[idx].slots.$each[idxSlot].opening.$dirty) return errors
      !this.$v.daysArray.$each[idx].slots.$each[idxSlot].opening.required && errors.push(this.$t('errors.required'))
      return errors
    },
    closingErrors(idx, idxSlot) {
      const errors = []
      if (!this.$v.daysArray.$each[idx].slots.$each[idxSlot].closing.$dirty) return errors
      !this.$v.daysArray.$each[idx].slots.$each[idxSlot].closing.required && errors.push(this.$t('errors.required'))
      return errors
    },
    duplicate(day) {
      this.duplicatingDay = day
    },
    endDuplicating(idx) {
      this.daysArray = this.daysArray.map(day => {
        if (this.duplicateArray.includes(day.key)) {
          return {
            key: day.key,
            open: this.duplicatingDay.open,
            h24: this.duplicatingDay.h24,
            slots: this.duplicatingDay.slots.map(slot => this.cloneSlot(slot)),
          }
        } else {
          return day
        }
      })
      this.duplicatingDay = null
      this.duplicateMenu[idx] = false
    },
    stopDuplicating() {
      this.duplicateArray = []
    },
    cloneSlot(slot) {
      return Object.assign(
        {},
        {
          opening: slot.opening,
          closing: slot.closing,
          menuOpening: false,
          menuClosing: false,
        }
      )
    },
    generateDays() {
      if (this.openingSlot) {
        const openingHoursArray = []
        const openingHoursObject = Object.entries(this.openingSlot.hours)

        for (const [day, time] of openingHoursObject) {
          let slots = time.map(t => ({
            opening: t.split('-')[0],
            closing: t.split('-')[1],
            menuOpening: false,
            menuClosing: false,
          }))
          const h24 =
            slots.length === 1 &&
            (slots[0].opening === '0:00' || slots[0].opening === '00:00') &&
            (slots[0].closing === '0:00' || slots[0].closing === '00:00')
          if (h24) {
            slots = []
          }
          openingHoursArray.push({
            key: day,
            open: slots.length > 0 || h24,
            h24,
            slots,
          })
        }
        this.daysArray = openingHoursArray
        this.slotName = this.openingSlot.label
        this.startDate = this.openingSlot.dateFrom
        this.endDate = this.openingSlot.dateTo
      }
    },
    convertData() {
      const hours = {}
      this.daysArray.map(day => {
        if (!day.open) {
          hours[day.key] = []
        } else if (day.h24) {
          hours[day.key] = ['0:00-0:00']
        } else {
          hours[day.key] = day.slots.map(slot => `${slot.opening}-${slot.closing}`)
        }
      })
      return hours
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.openingSlot) {
          this.$emit(
            'save:update',
            Object.assign({}, this.openingSlot, {
              label: this.slotName,
              dateFrom: this.startDate,
              dateTo: this.endDate,
              hours: this.convertData(),
            })
          )
        } else {
          this.$emit('save:create', {
            label: this.slotName,
            dateFrom: this.startDate,
            dateTo: this.endDate,
            hours: this.convertData(),
          })
        }
      }
    },
    onDelete() {
      this.$emit('delete', this.openingSlot)
    },
  },
  validations() {
    return {
      daysArray: {
        $each: {
          slots: {
            $each: {
              opening: {
                required,
              },
              closing: {
                required,
              },
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss">
.modal-opening-hours {
  &__grid {
    &__wrapper {
      position: relative;

      &__block {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;

        &__day {
          grid-column: 1;
          font-size: 1rem;
          font-weight: 500;
        }

        &__status {
          grid-column: 2;
        }

        &__h24 {
          grid-column: 3;
        }

        &__duplicate {
          display: block;
          position: relative;
          grid-column: 4;
          justify-self: flex-end;

          &__checkbox {
            width: 100%;
          }
        }
      }

      &__slots {
        &__line {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
