<template>
  <swiper ref="carousel" :options="swiperOptions" @ready="checkPagination" class="carousel">
    <swiper-slide v-for="(item, i) in items" :key="i" class="carousel__slide">
      <slot :item="item"></slot>
    </swiper-slide>

    <div
      v-show="hasPagination"
      slot="pagination"
      class="swiper-pagination swiper-pagination-bullets carousel__pagination tw-mt-2"
    ></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Carousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        pagination: {
          el: `.swiper-pagination`,
          clickable: true,
        },
        simulateTouch: false,
      },
      hasPagination: false,
    }
  },
  mounted() {
    this.swiper.on('paginationUpdate', this.checkPagination)
    this.swiper.on('init', this.$emit('init'))
  },
  computed: {
    swiper() {
      return this.$refs.carousel && this.$refs.carousel.$swiper
    },
  },
  methods: {
    checkPagination() {
      this.hasPagination = this.swiper.pagination.bullets.length > 1
      this.swiper.allowTouchMove = this.hasPagination
    },
  },
}
</script>

<style lang="scss">
$carousel-pagination-size: 16px;

.carousel {
  width: 100%;

  &__slide {
    &__item {
      max-width: 100%;
    }
  }

  &__pagination {
    position: relative;
    bottom: 1px !important;
    height: $carousel-pagination-size;

    .swiper-pagination-bullet {
      opacity: 1;
      margin: 0 5px !important;
      border: 1px solid $color-primary;
      background: $color-bg;
      width: $carousel-pagination-size;
      height: $carousel-pagination-size;

      &-active {
        background: $color-primary;
      }
    }
  }
}
</style>
