<template>
  <div class="splash-screen tw-p-4 sm:tw-p-6">
    <div class="splash-screen__container">
      <div class="splash-screen__container__title tw-text-2xl tw-font-bold tw-mb-8">
        {{ $t('splashScreen.title') }}
      </div>
      <v-progress-circular indeterminate color="primary" :size="64" :width="6"></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
}
</script>

<style lang="scss">
.splash-screen {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;

  &__container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
