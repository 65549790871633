<template>
  <div class="bottom">
    <v-btn class="success" rounded large @click="$emit('submit')" :loading="saving" :disabled="saving || !isEditable">
      {{ $t('bottom.cta') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  props: {
    saving: {
      type: Boolean,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.bottom {
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  border-top: 1px solid map-deep-get($material-light, 'banner', 'border');
  background: map-get($material-light, 'app-bar');
  width: 100%;
  height: 64px;
}
</style>
