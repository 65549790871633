var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"dialog":_vm.dialog,"hour-slot":_vm.exceptionalSlot,"title-create":_vm.$t('exceptionalHours.modal.titleCreate'),"title-update":_vm.$t('exceptionalHours.modal.titleUpdate'),"title-delete":_vm.$t('exceptionalHours.modal.titleDelete'),"description-delete":_vm.$t('exceptionalHours.modal.descriptionDelete')},on:{"close":function($event){return _vm.$emit('close')},"delete":_vm.onDelete,"submit":_vm.onSubmit}},[_c('v-text-field',{attrs:{"label":_vm.$t('exceptionalHours.modal.slotName'),"error-messages":_vm.nameErrors,"outlined":""},model:{value:(_vm.hoursFormatted.label),callback:function ($$v) {_vm.$set(_vm.hoursFormatted, "label", $$v)},expression:"hoursFormatted.label"}}),_c('div',{staticClass:"modal-exceptional-hours__grid"},[_c('div',{staticClass:"modal-exceptional-hours__grid__wrapper"},[_c('div',{staticClass:"modal-exceptional-hours__grid__wrapper__block tw-gap-4 sm:tw-gap-6"},[_c('v-checkbox',{staticClass:"modal-exceptional-hours__grid__wrapper__block__h24 tw-mt-4 tw-pt-0",attrs:{"label":"Plage","hide-details":""},on:{"change":_vm.clearDate},model:{value:(_vm.hoursFormatted.isRange),callback:function ($$v) {_vm.$set(_vm.hoursFormatted, "isRange", $$v)},expression:"hoursFormatted.isRange"}}),_c('v-menu',{ref:"menuDate",attrs:{"close-on-content-click":false,"return-value":_vm.hoursFormatted.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.hoursFormatted, "date", $event)},"update:return-value":function($event){return _vm.$set(_vm.hoursFormatted, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.hoursFormatted.isRange ? _vm.$t('exceptionalHours.modal.dateRange') : _vm.$t('exceptionalHours.modal.date'),"outlined":"","readonly":"","error-messages":_vm.dateErrors},model:{value:(_vm.computedDate),callback:function ($$v) {_vm.computedDate=$$v},expression:"computedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"range":_vm.hoursFormatted.isRange,"locale":_vm.$i18n.locale,"first-day-of-week":1},on:{"change":function($event){return _vm.$refs.menuDate.save(_vm.hoursFormatted.date)}},model:{value:(_vm.hoursFormatted.date),callback:function ($$v) {_vm.$set(_vm.hoursFormatted, "date", $$v)},expression:"hoursFormatted.date"}})],1),_c('v-switch',{staticClass:"modal-exceptional-hours__grid__wrapper__block__status tw-mt-4 tw-pt-0",attrs:{"hide-details":"","label":_vm.hoursFormatted.open ? _vm.$t('common.label.open') : _vm.$t('common.label.closed')},on:{"change":_vm.toggleSlots},model:{value:(_vm.hoursFormatted.open),callback:function ($$v) {_vm.$set(_vm.hoursFormatted, "open", $$v)},expression:"hoursFormatted.open"}})],1),(_vm.hoursFormatted.open)?_c('div',{staticClass:"modal-exceptional-hours__grid__wrapper__slots"},_vm._l((_vm.hoursFormatted.slots),function(slot,idxSlot){return _c('div',{key:("slot-" + idxSlot),staticClass:"modal-exceptional-hours__grid__wrapper__slots__line tw-gap-4 sm:tw-gap-6"},[_c('v-menu',{ref:("menuOpeningTime" + idxSlot),refInFor:true,attrs:{"close-on-content-click":false,"return-value":slot.opening,"transition":"scale-transition","offset-y":"","min-width":"290px","max-width":"360px"},on:{"update:returnValue":function($event){return _vm.$set(slot, "opening", $event)},"update:return-value":function($event){return _vm.$set(slot, "opening", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.openingErrors(idxSlot),"label":_vm.$t('common.label.opening'),"readonly":""},model:{value:(slot.opening),callback:function ($$v) {_vm.$set(slot, "opening", $$v)},expression:"slot.opening"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(slot.menuOpening),callback:function ($$v) {_vm.$set(slot, "menuOpening", $$v)},expression:"slot.menuOpening"}},[(slot.menuOpening)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedStep,"full-width":"","format":"24hr"},on:{"click:minute":function($event){_vm.$refs[("menuOpeningTime" + idxSlot)][0].save(slot.opening)}},model:{value:(slot.opening),callback:function ($$v) {_vm.$set(slot, "opening", $$v)},expression:"slot.opening"}}):_vm._e()],1),_c('v-menu',{ref:("menuClosingTime" + idxSlot),refInFor:true,attrs:{"close-on-content-click":false,"return-value":slot.closing,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(slot, "closing", $event)},"update:return-value":function($event){return _vm.$set(slot, "closing", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.closingErrors(idxSlot),"label":_vm.$t('common.label.closing'),"readonly":""},model:{value:(slot.closing),callback:function ($$v) {_vm.$set(slot, "closing", $$v)},expression:"slot.closing"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(slot.menuClosing),callback:function ($$v) {_vm.$set(slot, "menuClosing", $$v)},expression:"slot.menuClosing"}},[(slot.menuClosing)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedStep,"full-width":"","format":"24hr"},on:{"click:minute":function($event){_vm.$refs[("menuClosingTime" + idxSlot)][0].save(slot.closing)}},model:{value:(slot.closing),callback:function ($$v) {_vm.$set(slot, "closing", $$v)},expression:"slot.closing"}}):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(idxSlot === 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"modal-exceptional-hours__grid__wrapper__slots__line__cta",attrs:{"disabled":!_vm.hoursFormatted.slots[_vm.hoursFormatted.slots.length - 1].opening ||
                  !_vm.hoursFormatted.slots[_vm.hoursFormatted.slots.length - 1].closing ||
                  _vm.hoursFormatted.slots.length === 2,"icon":"","large":""},on:{"click":_vm.addSlot}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-alarm-plus ")])],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('common.cta.addSlot'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(idxSlot > 0)?_c('v-btn',_vm._g(_vm._b({staticClass:"modal-exceptional-hours__grid__wrapper__slots__line__cta",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.deleteSlot(idxSlot)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('common.cta.removeSlot'))+" ")])],1)}),0):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }