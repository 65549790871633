<template>
  <SplashScreen v-if="loading && !error" />
  <div class="home tw-flex tw-flex-col" v-else-if="!loading && !error && type === 'location'">
    <Banner :title="location.name" :subtitle="locationAddress" />
    <v-container class="tw-px-4 tw-pt-4 sm:tw-px-6 sm:tw-pt-6 home__container">
      <v-fade-transition>
        <v-alert
          type="warning"
          class="tw-mb-4 sm:tw-mb-6"
          v-if="unsavedData"
          v-html="
            $t('home.unsavedData', {
              location: location.name,
            })
          "
        >
        </v-alert>
      </v-fade-transition>
      <v-fade-transition>
        <v-alert
          type="error"
          class="tw-mb-4 sm:tw-mb-6"
          v-if="locationHistory && locationHistory.comment && locationHistory.status === 'rejected'"
        >
          {{ locationHistory.comment }}
        </v-alert>
      </v-fade-transition>
      <v-fade-transition>
        <v-alert
          type="error"
          icon="mdi-alert-circle-outline"
          class="tw-mb-4 sm:tw-mb-6"
          v-if="locationHistory && !isEditable"
        >
          <span v-if="locationHistory.status === 'validated'">{{ $t('home.validatedData') }}</span>
          <span v-if="locationHistory.campaign_expired">{{ $t('home.campaignExpired') }}</span>
        </v-alert>
      </v-fade-transition>
      <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 sm:tw-gap-6">
        <LocationOpeningHoursSubGroups
          v-if="isLocationWithGroup"
          :location-sub-groups="locationSubGroups"
          :selected-oh-sub-group-id="ohSubGroupId"
          :is-editable="isEditable"
          @select-slot="selectOhSubGroup"
        />
        <LocationOpeningHours
          v-else
          :opening-hours="existingOpeningHours"
          :is-editable="isEditable"
          @add-slot="dialogOpeningHours = true"
          @edit-slot="editOpeningSlot"
        />
        <LocationExceptionalHours
          :exceptional-hours="existingExceptionalHours"
          :is-editable="isEditable"
          @add-slot="dialogExceptionalHours = true"
          @edit-slot="editExceptionalHour"
        />
      </div>
    </v-container>
    <Bottom @submit="onSubmit" :saving="saving" :is-editable="isEditable && (!sameSubGroup || unsavedData)" />
    <ModalOpeningHours
      :dialog="dialogOpeningHours"
      :opening-slot="hourSlot"
      @close="closeModal"
      @save:update="updateOpeningSlot"
      @save:create="createOpeningSlot"
      @delete="deleteOpeningSlot"
    />
    <ModalExceptionalHours
      :dialog="dialogExceptionalHours"
      :exceptional-slot="hourSlot"
      @close="closeModal"
      @save:update="updateExceptionalSlot"
      @save:create="createExceptionalSlot"
      @delete="deleteExceptionalSlot"
    />
    <v-snackbar :timeout="5000" v-model="notificationUserExpressSuccess" centered top color="success">
      <span
        v-html="
          $t('home.notificationUserExpressSuccess', {
            location: location.name,
          })
        "
      ></span>
    </v-snackbar>
    <v-snackbar :timeout="5000" v-model="notificationError" centered top color="error">
      <span v-html="$t('home.notificationError')"></span>
    </v-snackbar>
  </div>
  <div
    class="home tw-flex tw-flex-col tw-h-full tw-justify-center tw-items-center"
    v-else-if="!loading && !error && type === 'standalone'"
  >
    <v-container class="tw-px-4 tw-pt-4 sm:tw-px-6 sm:tw-pt-6">
      <Standalone :creating="creating" @submit="onCampaignCreation" />
    </v-container>
    <v-snackbar :timeout="5000" v-model="notificationCampaignSuccess" centered top color="success">
      <span v-html="$t('home.notificationCampaignSuccess')"></span>
    </v-snackbar>
    <v-snackbar :timeout="5000" v-model="notificationError" centered top color="error">
      <span v-html="$t('home.notificationError')"></span>
    </v-snackbar>
  </div>
  <Error v-else />
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SplashScreen from '@/components/SplashScreen.vue'
import Error from '@/components/Error.vue'
import Banner from '@/components/Banner.vue'
import Bottom from '@/components/Bottom.vue'
import Standalone from '@/components/Standalone.vue'
import LocationOpeningHours from '@/components/Location/OpeningHours.vue'
import LocationOpeningHoursSubGroups from '@/components/Location/OpeningHoursSubGroups.vue'
import LocationExceptionalHours from '@/components/Location/ExceptionalHours.vue'
import ModalOpeningHours from '@/components/Modal/OpeningHours.vue'
import ModalExceptionalHours from '@/components/Modal/ExceptionalHours.vue'

export default {
  name: 'Home',
  components: {
    SplashScreen,
    Error,
    Banner,
    Bottom,
    Standalone,
    LocationOpeningHours,
    LocationOpeningHoursSubGroups,
    LocationExceptionalHours,
    ModalOpeningHours,
    ModalExceptionalHours,
  },
  data: () => ({
    dialogOpeningHours: false,
    dialogExceptionalHours: false,
    hourSlot: null,
    notificationCampaignSuccess: false,
    notificationUserExpressSuccess: false,
    notificationError: false,
    loading: true,
    saving: false,
    creating: false,
    error: false,
    type: '',
    ohSubGroupId: null,
  }),
  async created() {
    const token = this.$route.query.token
    const ltoken = this.$route.query.ltoken
    if (token && ltoken) {
      try {
        this.saveTokens({ token, ltoken })
        await Promise.all([
          this.getLocation(),
          this.getLocationHistory(),
          this.getOpeningHours(),
          this.getExceptionalHours(),
        ])
        if (this.isLocationWithGroup) {
          await this.getlocationSubGroups(this.location.openingHoursGroupId)
          this.ohSubGroupId = this.currentOhSubGroupId
        }
        this.type = 'location'
        this.loading = false
      } catch (err) {
        this.loading = false
        this.error = true
      }
    } else {
      this.type = 'standalone'
      this.loading = false
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.warnUser)
  },
  computed: {
    ...mapState({
      location: state => state.location.location,
      locationHistory: state => state.location.locationHistory,
      locationOpeningHours: state => state.location.locationOpeningHours,
      locationExceptionalHours: state => state.location.locationExceptionalHours,
      tmpLocationOpeningHours: state => state.location.tmpLocationOpeningHours,
      tmpLocationExceptionalHours: state => state.location.tmpLocationExceptionalHours,
      locationSubGroups: state => state.location.locationSubGroups,
    }),
    ...mapGetters({
      existingOpeningHours: 'location/existingOpeningHours',
      existingExceptionalHours: 'location/existingExceptionalHours',
      isLocationWithGroup: 'location/isLocationWithGroup',
      currentOhSubGroupId: 'location/currentOhSubGroupId',
      currentTmpOhSubGroupId: 'location/currentTmpOhSubGroupId',
      hasTemporaryOh: 'location/hasTemporaryOh',
    }),
    locationAddress() {
      let address = this.location.street1
      if (this.location.street2) {
        address += `<br />${this.location.street2}`
      }
      address += this.location.postalCode
        ? `<br />${this.location.postalCode} ${this.location.city}`
        : `<br />${this.location.city}`
      return address
    },
    unsavedData() {
      if (this.sameSubGroup) {
        return JSON.stringify(this.locationExceptionalHours) !== JSON.stringify(this.tmpLocationExceptionalHours)
      }
      return (
        JSON.stringify(this.locationOpeningHours) !== JSON.stringify(this.tmpLocationOpeningHours) ||
        JSON.stringify(this.locationExceptionalHours) !== JSON.stringify(this.tmpLocationExceptionalHours)
      )
    },
    sameSubGroup() {
      if (this.isLocationWithGroup && !this.hasTemporaryOh) {
        return this.currentOhSubGroupId === this.location.openingHoursSubGroupId
      } else if (this.isLocationWithGroup && this.hasTemporaryOh) {
        return this.currentOhSubGroupId === this.currentTmpOhSubGroupId
      }
      return false
    },
    isEditable() {
      return this.locationHistory.status !== 'validated' && !this.locationHistory.campaign_expired
    },
  },
  methods: {
    ...mapActions({
      saveTokens: 'location/saveTokens',
      getLocation: 'location/getLocation',
      getLocationHistory: 'location/getLocationHistory',
      getOpeningHours: 'location/getOpeningHours',
      getExceptionalHours: 'location/getExceptionalHours',
      getlocationSubGroups: 'location/getlocationSubGroups',
      updateOpeningHoursSubGroup: 'location/updateOpeningHoursSubGroup',
      updateOpeningHours: 'location/updateOpeningHours',
      createOpeningHours: 'location/createOpeningHours',
      deleteOpeningHours: 'location/deleteOpeningHours',
      saveOpeningHours: 'location/saveOpeningHours',
      updateExceptionalHours: 'location/updateExceptionalHours',
      createExceptionalHours: 'location/createExceptionalHours',
      deleteExceptionalHours: 'location/deleteExceptionalHours',
      saveExceptionalHours: 'location/saveExceptionalHours',
      saveLocationHistory: 'location/saveLocationHistory',
      createCampaign: 'location/createCampaign',
    }),
    closeModal() {
      this.dialogOpeningHours = false
      this.dialogExceptionalHours = false
      this.hourSlot = null
    },
    editOpeningSlot(item) {
      this.hourSlot = item
      this.dialogOpeningHours = true
    },
    updateOpeningSlot(item) {
      this.updateOpeningHours(item)
      this.closeModal()
    },
    createOpeningSlot(item) {
      this.createOpeningHours(item)
      this.closeModal()
    },
    deleteOpeningSlot(item) {
      this.deleteOpeningHours(item)
      this.closeModal()
    },
    editExceptionalHour(item) {
      this.hourSlot = item
      this.dialogExceptionalHours = true
    },
    updateExceptionalSlot(item) {
      this.updateExceptionalHours(item)
      this.closeModal()
    },
    createExceptionalSlot(item) {
      this.createExceptionalHours(item)
      this.closeModal()
    },
    deleteExceptionalSlot(item) {
      this.deleteExceptionalHours(item)
      this.closeModal()
    },
    selectOhSubGroup(ohSubGroup) {
      this.ohSubGroupId = ohSubGroup.id
      this.updateOpeningHoursSubGroup(ohSubGroup)
    },
    warnUser(e) {
      e.preventDefault()
      if (this.unsavedData) {
        e.returnValue = ''
      }
    },
    async onSubmit() {
      this.saving = true
      try {
        await Promise.all([this.saveOpeningHours(), this.saveExceptionalHours()])
        await this.saveLocationHistory()
        this.notificationUserExpressSuccess = true
        this.saving = false
      } catch {
        this.notificationError = true
        this.saving = false
      }
    },
    async onCampaignCreation(email) {
      this.creating = true
      try {
        await this.createCampaign(email)
        this.notificationCampaignSuccess = true
        this.creating = false
      } catch {
        this.notificationError = true
        this.creating = false
      }
    },
  },
}
</script>

<style lang="scss">
.home {
  &__container {
    padding-bottom: 80px;
  }
}
</style>
