<template>
  <Card
    :data="openingHours"
    :title="$t('openingHours.title')"
    :no-data="$t('openingHours.noData')"
    :cta="$t('openingHours.cta')"
    :is-editable="isEditable"
    @click="onClick"
  >
    <Carousel :items="openingHours">
      <template v-slot:default="props">
        <div
          class="location-opening-hours__slide tw-p-2 sm:tw-p-3"
          :class="{ 'location-opening-hours__slide--editable': isEditable }"
          @click="isEditable ? editSlot(props.item) : false"
        >
          <div class="tw-mb-1">
            {{
              props.item.label
                ? props.item.label
                : $t('common.hourSet', {
                    position: props.item.position,
                  })
            }}
          </div>
          <div
            class="location-opening-hours__slide__slot tw-flex tw-mb-2 tw-justify-between tw-text-sm tw-text-gray-400"
            v-for="(hour, day, idx) in props.item.hours"
            :key="`opening-hour-${idx}`"
          >
            <div class="tw-font-medium">
              {{ $t(`common.days.${day}`) }}
            </div>
            <div class="tw-flex tw-flex-col tw-items-end">
              <div v-if="hour.length === 0">
                {{ $t('common.label.closed') }}
              </div>
              <div
                v-else-if="hour.length === 1 && hour[0].split('-')[0] === '0:00' && hour[0].split('-')[1] === '0:00'"
              >
                {{ $t('common.label.h24') }}
              </div>
              <div v-else v-for="(slot, j) in hour" :key="`opening-hour-time-${j}`">
                {{ slot.split('-')[0] }} - {{ slot.split('-')[1] }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Carousel>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue'
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'LocationOpeningHours',
  components: {
    Card,
    Carousel,
  },
  props: {
    openingHours: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('add-slot')
    },
    editSlot(item) {
      this.$emit('edit-slot', item)
    },
  },
}
</script>

<style lang="scss">
.location-opening-hours {
  &__slide {
    &--editable {
      cursor: pointer;

      &:hover {
        border-radius: 8px;
        background: rgba($black, 0.04);
      }
    }

    &__slot {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
