<template>
  <v-dialog
    :value="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="700px"
    class="ma-4"
    persistent
  >
    <v-card tile>
      <v-card-title class="tw-text-lg tw-font-medium tw-p-4 sm:tw-px-6 sm:tw-py-4">
        <div class="tw-truncate" v-if="hourSlot">{{ titleUpdate }}</div>
        <div class="tw-truncate" v-else>{{ titleCreate }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px" class="tw-p-4 sm:tw-p-6">
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="sm:tw-px-6 sm:tw-py-3">
        <v-btn class="tw-px-4" text rounded large @click="$emit('close')">
          {{ $t('common.cta.close') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogDelete" persistent max-width="370" v-if="hourSlot">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="tw-px-4" color="red" outlined rounded icon large v-bind="attrs" v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="tw-text-lg tw-font-medium tw-p-4 sm:tw-px-6 sm:tw-py-4">
              <div class="tw-truncate">{{ titleDelete }}</div>
            </v-card-title>
            <v-card-text class="tw-text-base tw-px-4 tw-py-0 sm:tw-px-6 tw-pb-2"> {{ descriptionDelete }} </v-card-text>
            <v-card-actions class="sm:tw-px-6 sm:tw-py-3">
              <v-btn class="tw-px-4" text rounded large @click="dialogDelete = false">
                {{ $t('common.cta.no') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="tw-px-4" color="red" text rounded large @click="onDelete">
                {{ $t('common.cta.yes') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn class="primary tw-px-4" @click="$emit('submit')" rounded large>
          {{ $t('common.cta.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    hourSlot: {
      type: Object,
      required: false,
      default: null,
    },
    titleUpdate: {
      type: String,
      required: true,
    },
    titleCreate: {
      type: String,
      required: true,
    },
    titleDelete: {
      type: String,
      required: true,
    },
    descriptionDelete: {
      type: String,
      required: true,
    },
  },
  data() {
    return this.initialState()
  },
  watch: {
    dialog(val) {
      if (val) {
        Object.assign(this.$data, this.initialState())
      }
    },
  },
  methods: {
    initialState() {
      return {
        dialogDelete: false,
      }
    },
    onDelete() {
      this.dialogDelete = false
      this.$emit('delete')
    },
  },
}
</script>
