<template>
  <Card
    :data="locationSubGroups"
    :title="$t('openingHoursSubGroups.title')"
    :no-data="$t('openingHoursSubGroups.noData')"
    :cta="$t('openingHoursSubGroups.cta')"
    :is-editable="isEditable"
    @click="onClick"
  >
    <Carousel :items="locationSubGroups" @init="carouselInit" ref="carousel">
      <template v-slot:default="props">
        <div class="location-opening-hours-sub-groups__slide tw-p-2 sm:tw-p-3">
          <div class="tw-mb-1 tw-flex tw-items-center">
            {{ props.item.name }}
            <v-fade-transition>
              <v-chip v-if="selectedOhSubGroupId === props.item.id" class="tw-ml-3" color="info" label x-small>
                <v-icon left x-small> mdi-check </v-icon>
                {{ $t('openingHoursSubGroups.active') }}
              </v-chip>
            </v-fade-transition>
          </div>
          <div
            class="location-opening-hours-sub-groups__slide__slot tw-flex tw-mb-2 tw-justify-between tw-text-sm tw-text-gray-400"
            v-for="(hour, day, idx) in props.item.hours"
            :key="`opening-hour-${idx}`"
          >
            <div class="tw-font-medium">
              {{ $t(`common.days.${day}`) }}
            </div>
            <div class="tw-flex tw-flex-col tw-items-end">
              <div v-if="hour.length === 0">
                {{ $t('common.label.closed') }}
              </div>
              <div
                v-else-if="hour.length === 1 && hour[0].split('-')[0] === '0:00' && hour[0].split('-')[1] === '0:00'"
              >
                {{ $t('common.label.h24') }}
              </div>
              <div v-else v-for="(slot, j) in hour" :key="`opening-hour-time-${j}`">
                {{ slot.split('-')[0] }} - {{ slot.split('-')[1] }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Carousel>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue'
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'LocationOpeningHoursSubGroups',
  components: {
    Card,
    Carousel,
  },
  props: {
    locationSubGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedOhSubGroupId: {
      type: Number,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    carouselInit() {
      this.locationSubGroups.forEach((ohSubGroup, i) => {
        if (this.selectedOhSubGroupId === ohSubGroup.id) {
          this.$refs.carousel.swiper.activeIndex = i
        }
      })
    },
    onClick() {
      this.$emit('select-slot', this.locationSubGroups[this.$refs.carousel.swiper.activeIndex])
    },
  },
}
</script>

<style lang="scss">
.location-opening-hours-sub-groups {
  &__slide {
    &--editable {
      cursor: pointer;

      &:hover {
        border-radius: 8px;
        background: rgba($black, 0.04);
      }
    }

    &__slot {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
