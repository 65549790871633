<template>
  <v-app-bar app class="banner" height="80">
    <v-container class="banner__container tw-px-0 sm:tw-px-6 tw-py-0" :fluid="fluid">
      <div class="banner__container--left tw-overflow-x-hidden">
        <div class="tw-text-lg tw-font-bold tw-truncate banner__container__title">
          {{ title }}
        </div>
        <div class="tw-text-xs" v-html="subtitle"></div>
      </div>
      <div class="banner__container--right">
        <slot></slot>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderBanner',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    fluid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.banner {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--left {
      flex: 1;
    }
  }
}
</style>
