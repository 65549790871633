<template>
  <v-card class="tw-flex tw-flex-col location-opening-hours" height="100%">
    <v-card-title class="tw-text-lg tw-font-medium sm:tw-p-6 tw-p-4">
      <div class="tw-truncate">{{ title }}</div>
    </v-card-title>
    <div class="tw-mb-4 sm:tw-mb-6 tw-px-4 sm:tw-px-6 tw--mx-2 tw--mt-2 sm:tw--mx-3 sm:tw--mt-3" v-if="data.length">
      <slot></slot>
    </div>
    <div class="tw-mb-4 sm:tw-mb-6 tw-px-4 sm:tw-px-6" v-else>
      {{ noData }}
    </div>
    <div class="tw-flex tw-justify-center tw-px-4 tw-pb-4 sm:tw-px-6 sm:tw-pb-6 tw-mt-auto">
      <v-btn rounded large color="primary" outlined @click="$emit('click')" :disabled="!isEditable">
        {{ cta }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    noData: {
      type: String,
      required: true,
    },
    cta: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
