<template>
  <Modal
    :dialog="dialog"
    :hour-slot="exceptionalSlot"
    :title-create="$t('exceptionalHours.modal.titleCreate')"
    :title-update="$t('exceptionalHours.modal.titleUpdate')"
    :title-delete="$t('exceptionalHours.modal.titleDelete')"
    :description-delete="$t('exceptionalHours.modal.descriptionDelete')"
    @close="$emit('close')"
    @delete="onDelete"
    @submit="onSubmit"
  >
    <v-text-field
      :label="$t('exceptionalHours.modal.slotName')"
      v-model="hoursFormatted.label"
      :error-messages="nameErrors"
      outlined
    ></v-text-field>

    <div class="modal-exceptional-hours__grid">
      <div class="modal-exceptional-hours__grid__wrapper">
        <div class="modal-exceptional-hours__grid__wrapper__block tw-gap-4 sm:tw-gap-6">
          <v-checkbox
            class="modal-exceptional-hours__grid__wrapper__block__h24 tw-mt-4 tw-pt-0"
            label="Plage"
            v-model="hoursFormatted.isRange"
            hide-details
            @change="clearDate"
          >
          </v-checkbox>
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="hoursFormatted.date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDate"
                :label="
                  hoursFormatted.isRange ? $t('exceptionalHours.modal.dateRange') : $t('exceptionalHours.modal.date')
                "
                outlined
                readonly
                :error-messages="dateErrors"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="hoursFormatted.date"
              :range="hoursFormatted.isRange"
              @change="$refs.menuDate.save(hoursFormatted.date)"
              :locale="$i18n.locale"
              :first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
          <v-switch
            class="modal-exceptional-hours__grid__wrapper__block__status tw-mt-4 tw-pt-0"
            v-model="hoursFormatted.open"
            @change="toggleSlots"
            hide-details
            :label="hoursFormatted.open ? $t('common.label.open') : $t('common.label.closed')"
          >
          </v-switch>
        </div>
        <div class="modal-exceptional-hours__grid__wrapper__slots" v-if="hoursFormatted.open">
          <div
            class="modal-exceptional-hours__grid__wrapper__slots__line tw-gap-4 sm:tw-gap-6"
            v-for="(slot, idxSlot) in hoursFormatted.slots"
            :key="`slot-${idxSlot}`"
          >
            <v-menu
              :ref="`menuOpeningTime${idxSlot}`"
              v-model="slot.menuOpening"
              :close-on-content-click="false"
              :return-value.sync="slot.opening"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="360px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slot.opening"
                  :error-messages="openingErrors(idxSlot)"
                  :label="$t('common.label.opening')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="slot.menuOpening"
                v-model="slot.opening"
                :allowed-minutes="allowedStep"
                full-width
                format="24hr"
                @click:minute="$refs[`menuOpeningTime${idxSlot}`][0].save(slot.opening)"
              ></v-time-picker>
            </v-menu>
            <v-menu
              :ref="`menuClosingTime${idxSlot}`"
              v-model="slot.menuClosing"
              :close-on-content-click="false"
              :return-value.sync="slot.closing"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="slot.closing"
                  :error-messages="closingErrors(idxSlot)"
                  :label="$t('common.label.closing')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="slot.menuClosing"
                v-model="slot.closing"
                :allowed-minutes="allowedStep"
                full-width
                format="24hr"
                @click:minute="$refs[`menuClosingTime${idxSlot}`][0].save(slot.closing)"
              ></v-time-picker>
            </v-menu>
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="modal-exceptional-hours__grid__wrapper__slots__line__cta"
                  v-if="idxSlot === 0"
                  :disabled="
                    !hoursFormatted.slots[hoursFormatted.slots.length - 1].opening ||
                    !hoursFormatted.slots[hoursFormatted.slots.length - 1].closing ||
                    hoursFormatted.slots.length === 2
                  "
                  @click="addSlot"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-alarm-plus </v-icon>
                </v-btn>
              </template>
              {{ $t('common.cta.addSlot') }}
            </v-tooltip>
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="modal-exceptional-hours__grid__wrapper__slots__line__cta"
                  v-if="idxSlot > 0"
                  @click="deleteSlot(idxSlot)"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              {{ $t('common.cta.removeSlot') }}
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { formatedDate } from '@/utils/date.util'
import Modal from '@/components/Modal.vue'

export default {
  name: 'ModalExceptionalHours',
  components: {
    Modal,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    exceptionalSlot: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return this.initialState()
  },
  mixins: [validationMixin],
  watch: {
    dialog(val) {
      if (val) {
        this.$v.$reset()
        Object.assign(this.$data, this.initialState())
        this.generateData()
      }
    },
  },
  computed: {
    computedDate: {
      get() {
        if (this.hoursFormatted.isRange) {
          return this.hoursFormatted.date
            ? `${formatedDate(this.hoursFormatted.date[0], 'L', this.$i18n.locale)} - ${formatedDate(
                this.hoursFormatted.date[1],
                'L',
                this.$i18n.locale
              )}`
            : ''
        }
        return this.hoursFormatted.date ? formatedDate(this.hoursFormatted.date, 'L', this.$i18n.locale) : ''
      },
      set(date) {
        this.hoursFormatted.date = date ? date : ''
      },
    },
    nameErrors() {
      const errors = []
      if (!this.$v.hoursFormatted.label.$dirty) return errors
      !this.$v.hoursFormatted.label.required && errors.push(this.$t('errors.required'))
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.hoursFormatted.date.$dirty) return errors
      !this.$v.hoursFormatted.date.required && errors.push(this.$t('errors.required'))
      return errors
    },
  },
  methods: {
    initialState() {
      return {
        menuDate: false,
        hoursFormatted: {
          label: '',
          open: false,
          isRange: false,
          date: null,
          slots: [],
        },
      }
    },
    toggleSlots() {
      this.hoursFormatted = {
        ...this.hoursFormatted,
        slots: this.hoursFormatted.open
          ? [
              {
                opening: '',
                closing: '',
                menuOpening: false,
                menuClosing: false,
              },
            ]
          : [],
      }
    },
    allowedStep(m) {
      return m % 5 === 0
    },
    addSlot() {
      this.hoursFormatted.slots.push({
        opening: '',
        closing: '',
        menuOpening: false,
        menuClosing: false,
      })
    },
    deleteSlot(index) {
      this.hoursFormatted.slots.splice(index, 1)
    },
    clearDate() {
      this.hoursFormatted.date = null
    },
    openingErrors(idxSlot) {
      const errors = []
      if (!this.$v.hoursFormatted.slots.$each[idxSlot].opening.$dirty) return errors
      !this.$v.hoursFormatted.slots.$each[idxSlot].opening.required && errors.push(this.$t('errors.required'))
      return errors
    },
    closingErrors(idxSlot) {
      const errors = []
      if (!this.$v.hoursFormatted.slots.$each[idxSlot].closing.$dirty) return errors
      !this.$v.hoursFormatted.slots.$each[idxSlot].closing.required && errors.push(this.$t('errors.required'))
      return errors
    },
    generateData() {
      if (this.exceptionalSlot) {
        // Generate date
        let date = null
        const dateFrom = this.exceptionalSlot.dateFrom
        const dateTo = this.exceptionalSlot.dateTo
        if (dateFrom && !dateTo) {
          date = dateFrom
        } else if (dateFrom && dateTo) {
          date = [dateFrom, dateTo]
        }

        // Generate time slots
        const slots = []
        const hours1 = this.exceptionalSlot.hours1
        const hours2 = this.exceptionalSlot.hours2
        if (hours1) {
          slots.push({
            opening: hours1.split('-')[0],
            closing: hours1.split('-')[1],
          })
        }
        if (hours2) {
          slots.push({
            opening: hours2.split('-')[0],
            closing: hours2.split('-')[1],
          })
        }

        this.hoursFormatted = {
          label: this.exceptionalSlot.label,
          open: !!this.exceptionalSlot.openStatus,
          isRange: typeof this.exceptionalSlot.dateFrom === 'string' && typeof this.exceptionalSlot.dateTo === 'string',
          date,
          slots,
        }
      }
    },
    convertData() {
      const date = this.hoursFormatted.date
      const hours = this.hoursFormatted.slots
      return {
        ...this.exceptionalSlot,
        label: this.hoursFormatted.label,
        openStatus: this.hoursFormatted.open,
        dateFrom: Array.isArray(date) ? date[0] : date,
        dateTo: Array.isArray(date) ? date[1] : null,
        hours1: hours.length >= 1 ? `${hours[0].opening}-${hours[0].closing}` : '',
        hours2: hours.length === 2 ? `${hours[1].opening}-${hours[1].closing}` : '',
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.exceptionalSlot) {
          this.$emit('save:update', Object.assign({}, this.convertData()))
        } else {
          this.$emit('save:create', this.convertData())
        }
      }
    },
    onDelete() {
      this.$emit('delete', this.exceptionalSlot)
    },
  },
  validations() {
    return {
      hoursFormatted: {
        label: {
          required,
        },
        date: {
          required,
        },
        slots: {
          $each: {
            opening: {
              required,
            },
            closing: {
              required,
            },
          },
        },
      },
    }
  },
}
</script>

<style lang="scss">
.modal-exceptional-hours {
  &__grid {
    &__wrapper {
      position: relative;

      &__block {
        display: flex;
        align-items: flex-start;
      }

      &__slots {
        &__line {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
