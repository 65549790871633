<template>
  <div class="standalone">
    <form @submit.prevent="onSubmit" novalidate autocomplete="off">
      <div class="tw-text-center">
        {{ $t('standalone.description') }}
      </div>
      <v-text-field
        v-model="email"
        :label="$t('standalone.email')"
        :error-messages="emailErrors"
        outlined
        background-color="white"
        class="tw-mt-8"
      ></v-text-field>

      <div class="tw-flex tw-justify-center">
        <v-btn class="success" type="submit" rounded large :loading="creating" :disabled="creating">
          {{ $t('standalone.cta') }}
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Standalone',
  props: {
    creating: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    email: '',
  }),
  mixins: [validationMixin],
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push(this.$t('errors.required'))
      !this.$v.email.email && errors.push(this.$t('errors.email'))
      return errors
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('submit', this.email)
      }
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    }
  },
}
</script>
