<template>
  <Card
    :data="exceptionalHours"
    :title="$t('exceptionalHours.title')"
    :no-data="$t('exceptionalHours.noData')"
    :cta="$t('exceptionalHours.cta')"
    :is-editable="isEditable"
    @click="onClick"
  >
    <div
      class="tw-p-2 sm:tw-p-3 location-exceptional-hours__slot"
      :class="{
        'location-exceptional-hours__slot--editable': isEditable,
      }"
      v-for="(exceptionalHour, idx) in exceptionalHours"
      :key="`exceptional-hour-${idx}`"
      @click="isEditable ? editSlot(exceptionalHour) : false"
    >
      <div>
        {{ exceptionalHour.label }}
      </div>
      <div class="tw-flex tw-justify-between tw-text-sm tw-text-gray-400">
        <div>
          <template
            v-if="
              exceptionalHour.dateFrom === exceptionalHour.dateTo ||
              (exceptionalHour.dateFrom && !exceptionalHour.dateTo)
            "
          >
            {{ $t('common.date.on') }} {{ date(exceptionalHour.dateFrom) }}
          </template>
          <template v-else>
            {{ $t('common.date.from') }} {{ date(exceptionalHour.dateFrom) }} {{ $t('common.date.to') }}
            {{ date(exceptionalHour.dateTo) }}
          </template>
        </div>
        <div class="tw-flex tw-flex-col tw-items-end">
          <div v-if="!exceptionalHour.openStatus">
            {{ $t('common.label.closed') }}
          </div>
          <div v-else-if="!exceptionalHour.hours1 && !exceptionalHour.hours2">
            {{ $t('common.label.open') }}
          </div>
          <template v-else>
            <div v-if="exceptionalHour.hours1">
              {{ exceptionalHour.hours1.split('-')[0] }} - {{ exceptionalHour.hours1.split('-')[1] }}
            </div>
            <div v-if="exceptionalHour.hours2">
              {{ exceptionalHour.hours2.split('-')[0] }} - {{ exceptionalHour.hours2.split('-')[1] }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'LocationExceptionalHours',
  components: {
    Card,
  },
  props: {
    exceptionalHours: {
      type: Array,
      required: false,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
    onClick() {
      this.$emit('add-slot')
    },
    editSlot(item) {
      this.$emit('edit-slot', item)
    },
  },
}
</script>

<style lang="scss">
.location-exceptional-hours {
  &__slot {
    &--editable {
      cursor: pointer;

      &:hover {
        border-radius: 8px;
        background: rgba($black, 0.04);
      }
    }
  }
}
</style>
